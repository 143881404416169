/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import { COOKIE_ID, COOKIE_TOKEN } from "../../components/utils/constants";
import { deleteCookie, getCookie } from "../../components/utils/cookies";
import { isStorageAvailable } from "../../components/utils/storage";
import { authentication, logoutSession, verifySession } from "./helpers";
import { Auth, AuthResponse, JWT } from "./types";

export enum Status {
  Loading,
  Ready,
  Success,
  Updating,
  Error,
}

export const useAuth = () => {
  const [status, setStatus] = React.useState(Status.Ready);
  const [message, setMessage] = React.useState("");

  async function verifyAuthentication({
    username,
    password,
  }: Auth): Promise<AuthResponse> {
    setStatus(Status.Loading);
    return authentication({ username, password })
      .then((response) => {
        if (response.status === 200) {
          setStatus(Status.Ready);
        } else {
          setMessage(response?.message || "");
          setStatus(Status.Error);
          setTimeout(() => {
            setMessage("");
            setStatus(Status.Ready);
          }, 3000);
        }
        return response;
      })
      .catch((error) => {
        setStatus(Status.Error);
        setMessage("Ocurrió un error inesperado");
        setTimeout(() => {
          setMessage("");
          setStatus(Status.Ready);
        }, 3000);
        return error;
      });
  }

  async function verifyAuthSession({ id, token }: JWT) {
    setStatus(Status.Loading);
    return verifySession({ id, token })
      .then((response) => {
        if (response.status === 200) {
          setStatus(Status.Success);
        } else {
          setMessage(response?.message || "");
          setStatus(Status.Error);
        }
        return response;
      })
      .catch((error) => {
        setStatus(Status.Error);
        return error;
      });
  }

  const destroyStorageAndCookies = (): void => {
    if (isStorageAvailable("sessionStorage")) {
      Object.keys(sessionStorage).forEach((key) => delete sessionStorage[key]);
    }
    if (isStorageAvailable("localStorage")) {
      Object.keys(localStorage).forEach((key) => delete localStorage[key]);
    }
    const token = getCookie(COOKIE_TOKEN);
    const idUser = getCookie(COOKIE_ID);
    if (token) {
      deleteCookie(COOKIE_TOKEN);
    }
    if (idUser) {
      deleteCookie(COOKIE_ID);
    }
  };

  async function logoutUser() {
    setStatus(Status.Loading);
    const id = getCookie(COOKIE_ID) || "";
    destroyStorageAndCookies();
    if (id) {
      return logoutSession(id)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        })
        .finally(() => {
          setStatus(Status.Ready);
        });
    }
  }
  return {
    verifyAuthentication,
    verifyAuthSession,
    logoutUser,
    status,
    message,
  };
};
