const firebaseConfig = {
  apiKey: "AIzaSyAPXXhBAGc4bFULYdTaNIB0cdn0d4IKcsM",
  authDomain: "labbio-client-1bc09.firebaseapp.com",
  projectId: "labbio-client-1bc09",
  storageBucket: "labbio-client-1bc09.appspot.com",
  messagingSenderId: "487450063471",
  appId: "1:487450063471:web:dd2537318545f6dcb3939a",
};

export default firebaseConfig;
