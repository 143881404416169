import React from "react";
import { Cover } from "../components/home/cover";
import { Services } from "../components/home/services";
import { VisionAndMision } from "../components/home/visionAndMision";
import { We } from "../components/home/we";
import { Footer } from "../components/ui/footer";
import { Header } from "../components/ui/header";
import { Nav } from "../components/ui/nav";

export const Home = () => {
  // React.useEffect(() => {
  //   const email = "yatamos10@mailinator.com";
  //   console.log("llego aqui");

  //   fetch(
  //     `https://elcomercio-elcomercio-sandbox.cdn.arcpublishing.com/pf/api/v3/content/fetch/verify-piano-user-status?_website=elcomercio&query=${encodeURI(
  //       JSON.stringify({ email: email })
  //     )}`
  //   )
  //     .then((e) => e.json())
  //     .then((res) => console.log(res))
  //     .catch((e) => console.log(e));
  // }, []);
  return (
    <>
      <Header></Header>
      <Nav></Nav>
      <Cover></Cover>
      <Services></Services>
      <VisionAndMision></VisionAndMision>
      <We></We>
      <Footer></Footer>
    </>
  );
};
