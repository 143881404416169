/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { verifySession } from "../hooks/useAuth/helpers";
import { Patient } from "../hooks/usePublic";
import { COOKIE_ID, COOKIE_TOKEN } from "../components/utils/constants";
import { deleteCookie, getCookie } from "../components/utils/cookies";
import { isStorageAvailable } from "../components/utils/storage";

export type ValuesOf<T> = { [P in keyof T]: T[P] };

type StatusOptions = ValuesOf<authStatus>;
enum authStatus {
  Error,
  Loading,
  Ready,
}

type AuthProviderProps = {
  children: React.ReactNode;
};

type AuthProviderValue = {
  status: StatusOptions | undefined;
  user: Patient | undefined;
  updateAuth: (user: Patient | undefined) => void;
  cleanAuth: () => void;
  error?: any | undefined;
};

const AuthContext = React.createContext<AuthProviderValue | undefined>(
  undefined
);

const useAuthContext = (): AuthProviderValue => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext debe ser usado dentro de un AuthProvider");
  }
  return context;
};

const destroyStorageAndCookies = (): void => {
  if (isStorageAvailable("sessionStorage")) {
    Object.keys(sessionStorage).forEach((key) => delete sessionStorage[key]);
  }
  if (isStorageAvailable("localStorage")) {
    Object.keys(localStorage).forEach((key) => delete localStorage[key]);
  }
  const token = getCookie(COOKIE_TOKEN);
  const cookieId = getCookie(COOKIE_ID);
  if (token) {
    deleteCookie(COOKIE_TOKEN);
  }
  if (cookieId) {
    deleteCookie(COOKIE_ID);
  }
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const cleanAuth = () => {
    destroyStorageAndCookies();
    setValue({
      ...value,
      status: authStatus.Ready,
      user: undefined,
      error: undefined,
    });
  };

  const [value, setValue] = React.useState<AuthProviderValue>({
    user: undefined,
    updateAuth: function (user: Patient | undefined) {
      setValue({
        ...value,
        status: authStatus.Ready,
        user,
        error: undefined,
      });
    },
    cleanAuth,
    status: authStatus.Loading,
  });

  const initializeAuth = async (): Promise<AuthProviderValue> => {
    const COOKIE_ID_TOKEN = getCookie(COOKIE_ID);
    const COOKIE_AUTH_TOKEN = getCookie(COOKIE_TOKEN);
    if (COOKIE_ID && COOKIE_AUTH_TOKEN) {
      try {
        const getUser = await verifySession({
          id: COOKIE_ID_TOKEN,
          token: COOKIE_AUTH_TOKEN,
        });
        if (getUser.status === 200) {
          return {
            ...value,
            status: authStatus.Ready,
            user: getUser?.doc || undefined,
          };
        } else {
          destroyStorageAndCookies();
          return {
            ...value,
            status: authStatus.Ready,
          };
        }
      } catch (error) {
        destroyStorageAndCookies();
        return {
          ...value,
          status: authStatus.Ready,
        };
      }
    }
    return {
      ...value,
      status: authStatus.Ready,
    };
  };

  React.useEffect(() => {
    initializeAuth()
      .then((auth) => {
        setValue(auth);
      })
      .catch((error) => {
        setValue({
          ...value,
          status: authStatus.Error,
          error,
        });
      });
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider, authStatus, useAuthContext };
