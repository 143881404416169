import { PaginateResponse } from "../../hooks/types";
import {
  BackwardFastIcon,
  BackwardStepIcon,
  ForwardFastIcon,
  ForwardStepIcon,
} from "./icons";

type PaginateButtonsProps = {
  paginate: PaginateResponse | null;
  handleChange: (index: number, limit: number) => void;
};
export const PaginateButtons = ({
  paginate,
  handleChange,
}: PaginateButtonsProps) => {
  const {
    hasPrevPage = false,
    prevPage = 0,
    page = 0,
    hasNextPage = false,
    nextPage = 0,
    totalPages = 1,
    limit = 0,
  } = paginate || {};

  const styles = {
    border: "0",
    margin: "2px",
  };

  const classes = { button: "btn btn-info mx-2 text-white" };

  return (
    <>
      {hasPrevPage && page > 2 ? (
        <button
          className={classes.button}
          style={{ ...styles }}
          onClick={() => handleChange(1, limit)}
        >
          <BackwardFastIcon></BackwardFastIcon>
        </button>
      ) : null}
      {hasPrevPage ? (
        <button
          className={classes.button}
          style={{ ...styles }}
          onClick={() => handleChange(prevPage || 0, limit)}
        >
          <BackwardStepIcon></BackwardStepIcon>
        </button>
      ) : null}

      {page ? (
        <button className={classes.button} style={{ ...styles }}>
          {page}
        </button>
      ) : null}

      {hasNextPage ? (
        <button
          className={classes.button}
          style={{ ...styles }}
          onClick={() => handleChange(nextPage, limit)}
        >
          <ForwardStepIcon></ForwardStepIcon>
        </button>
      ) : null}

      {hasNextPage && totalPages !== page && totalPages !== page + 1 ? (
        <button
          style={{ ...styles }}
          className={classes.button}
          onClick={() => handleChange(totalPages, limit)}
        >
          <ForwardFastIcon></ForwardFastIcon>
        </button>
      ) : null}
    </>
  );
};
