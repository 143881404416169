import * as React from "react";
import { getCookie } from "../../components/utils/cookies";
// import Swal from "sweetalert2";

import { getExams } from "./helpers";
import { Exam } from "./index";
import { BodyParams, PaginateParams, PaginateResponse } from "../types";
import { COOKIE_ID, COOKIE_TOKEN } from "../../components/utils/constants";

export enum Status {
  Loading,
  Ready,
  Updating,
  Error,
}

export const useExams = () => {
  const [exams, setExams] = React.useState<Exam[]>([]);
  const [status, setStatus] = React.useState(Status.Loading);
  const [paginateData, setPaginateData] =
    React.useState<PaginateResponse | null>(null);
  const [searchFilter, setSearchFilter] = React.useState<BodyParams>({
    startDate: "",
    endDate: "",
    status: null,
  });

  async function getExamsByFilter(
    { startDate, endDate, status }: BodyParams,
    { limit, pageSize }: PaginateParams
  ) {
    const token = getCookie(COOKIE_TOKEN) || "";
    const id = getCookie(COOKIE_ID) || "";
    return getExams(
      token,
      { startDate, endDate, patientId: id, status },
      { limit, pageSize }
    )
      .then((response: PaginateResponse) => {
        if (response?.status === 200) {
          const { docs: ordersObtained = [] } = response || {};
          setExams(ordersObtained);
          setPaginateData(response);
        } else {
          // Swal.fire({
          //   icon: "error",
          //   title: "Algo salió mal!",
          //   text: response?.message || "",
          //   timer: 2000,
          //   confirmButtonColor: "#2e2868",
          // });
        }
        setStatus(Status.Ready);
        return response;
      })
      .catch((error) => {
        setStatus(Status.Error);
        return error;
      })
      .finally(() => {
        setSearchFilter({
          ...searchFilter,
          startDate,
          endDate,
          status,
        });
      });
  }

  function changePage(index: number, limit: number) {
    getExamsByFilter(searchFilter, { limit, pageSize: index });
  }

  return {
    exams,
    searchFilter,
    getExamsByFilter,
    paginateData,
    changePage,
    status,
  };
};
