import { GENERAL_API } from "../../env";
import { Auth, AuthResponse, JWT } from "./types";


export const authentication = ({
  username,
  password,
}: Auth): Promise<AuthResponse> =>
  fetch(`${GENERAL_API}/public/patient/auth`, {
    method: "POST",
    cache: "no-cache",
    body: JSON.stringify({
      codePatient: username,
      documentNumber: password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .catch((res) => res.json());

export const verifySession = ({ id, token }: JWT): Promise<AuthResponse> =>
  fetch(`${GENERAL_API}/public/patient/verify`, {
    method: "POST",
    cache: "no-cache",
    body: JSON.stringify({
      id: id,
      token: token,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .catch((res) => res.json());

export const logoutSession = (id: string): Promise<AuthResponse> =>
  fetch(`${GENERAL_API}/public/patient/logout`, {
    method: "POST",
    cache: "no-cache",
    body: JSON.stringify({
      id: id,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .catch((res) => res.json());
