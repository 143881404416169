import { GENERAL_API } from "../../env";
import { BodyParams, PaginateParams, PaginateResponse } from "../types";

export const getExams = (
  token: string,
  {
    startDate,
    endDate,
    patientId,
    status = null,
  }: BodyParams & { patientId: string },
  { limit = 5, pageSize = 1 }: PaginateParams
): Promise<PaginateResponse> =>
  fetch(
    `${GENERAL_API}/public/patient/exams/?limit=${limit}&pageSize=${pageSize}`,
    {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify({
        startDate,
        endDate,
        patientId,
        status,
      }),
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${token}`,
      },
    }
  )
    .then((res) => res.json())
    .catch((res) => res.json());
