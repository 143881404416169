// @ts-ignore
import Muestras from "../../assets/imgs/toma-muestras.png";
// @ts-ignore
import Camioneta from "../../assets/imgs/camioneta.jpg";
// @ts-ignore
import Doctor from "../../assets/imgs/doctor-oficina.png";

const classses = {
  services: "services",
  servicesTop: "services-top",
  servicesTitle: "services-title",
  servicesUnderline: "services-underline",
  servicesContainer: "services-container",
  serviceItem: "services-container--item",
  serviceItemImage: "services-container--item__image w-100",
  serviceItemTitle: "services-container--item__title",
  serviceItemDescription: "services-container--item__description",
};

export const Services = () => {
  return (
    <div className={classses.services} id="services">
      <div className={classses.servicesTop}>
        <p className={classses.servicesTitle}>Nuestros Servicios</p>
        <hr className={classses.servicesUnderline}></hr>
      </div>
      <div className={classses.servicesContainer}>
        <div className={classses.serviceItem}>
          <img
            src={Muestras}
            alt="análisis y ecografías"
            className={classses.serviceItemImage}
          ></img>

          <p className={classses.serviceItemTitle}>Análisis y ecografías</p>
          <p className={classses.serviceItemDescription}>
            Con el profesionalismo que nos caracteriza ofrecemos una variedad de
            análisis clínicos y ecografías para brindarte el resultado más
            confiable.
          </p>
        </div>
        <div className={classses.serviceItem}>
          <img
            src={Doctor}
            alt="analisis de consulta médica especializada"
            className={classses.serviceItemImage}
          ></img>
          <p className={classses.serviceItemTitle}>
            Consulta médica especializada
          </p>
          <p className={classses.serviceItemDescription}>
            LAB BIO cuenta con médicos en diferentes especialidades de niños y
            adultos para una atención integral.
          </p>
        </div>
        <div className={classses.serviceItem}>
          <img
            src={Camioneta}
            alt="servicio adomicilio"
            className={classses.serviceItemImage}
          ></img>
          <p className={classses.serviceItemTitle}>
            Tomas de muestras a domicilio
          </p>
          <p className={classses.serviceItemDescription}>
            Contamos con unidades móviles y un sistema adecuado de transporte de
            muestras y la entrega de resultados con total confidencialidad.
          </p>
        </div>
      </div>
    </div>
  );
};
