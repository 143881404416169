// @ts-ignore
import Personal from "../../assets/imgs/personal.png";

const classes = {
  we: "we",
  weContainer: "we-container",
  weBackground: "we-background",
  weFirstItem: "we-container--fi",
  weFirstItemTitle: "we-container--fi__title",
  weFirstItemDescription: "we-container--fi__description",
};

export const We = () => {
  return (
    <div className={classes.we} id="who_are_we">
      <div className={classes.weContainer}>
        <div className={classes.weFirstItem}>
          <p className={classes.weFirstItemTitle}>¿Quiénes somos?</p>
          <img
            src={Personal}
            alt="camioneta-labbio"
            className={classes.weBackground}
          ></img>
          <p className={classes.weFirstItemDescription}>
            LAB - BIO es una empresa dedicada a la prestación de servicio de
            análisis clínicos y microbiológicos a la población trujillana.
            Iniciamos en el año 1991 en el distrito de La Esperanza con el
            nombre de laboratorio clínico “JÁUREGUI”. Dos años después iniciamos
            un nuevo desafío en el centro histórico de Trujillo con el nombre de
            "Laboratorio de Bioanálisis SRL". Con la ayuda de Dios y la
            dedicación de nuestro equipo humano hemos ampliado nuestros
            servicios para brindar consultas médicas especializadas en medicina
            interna, pediatría, cirugía general, alergología e inmunología,
            traumatología, medicina familiar, y actualmente con el nuevo
            servicio de ecografías para el complemento ideal del diagnóstico
            oportuno del paciente. Nuestro equipo de apoyo de técnicos en
            laboratorio clínico, microbiólogos, médicos y equipo automatizados
            de alta eficiencia y calidad nos permiten brindar resultados
            confiables con tecnología a la vanguardia de las ciencias de la
            salud.
          </p>
        </div>
      </div>
    </div>
  );
};
