const classes = {
  vision: "vision",
  visionContainer: "vision-container",
  visionItem: "vision-container--item",
  visionItemTitle: "vision-container--item__title",
  visionItemDescription: "vision-container--item__description",
};

export const VisionAndMision = () => {
  return (
    <div className={classes.vision} id="objetives">
      <div className={classes.visionContainer}>
        <div className={classes.visionItem}>
          <p className={classes.visionItemTitle}>Visión</p>
          <p className={classes.visionItemDescription}>
            Ser un laboratorio reconocido por su excelente atención humana,
            resultados confiables y oportunos.
          </p>
        </div>
        <div className={classes.visionItem}>
          <p className={classes.visionItemTitle}>Nuestra Misión</p>
          <p className={classes.visionItemDescription}>
            Realizar exámenes de laboratorio con excelencia, rapidez, calidad y
            confiabilidad con fines de diagnóstico preventivo y en control de
            tratamientos para satisfacer las necesidades de nuestros clientes, y
            contar con equipo humano y tecnológico calificado para la entrega de
            un servicio EXCELENTE.
          </p>
        </div>
      </div>
    </div>
  );
};
