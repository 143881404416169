/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useHistory } from "react-router-dom";
// @ts-ignore
import Banner from "../assets/imgs/logo-blanco-2.png";
import { PasswordIcon, UserIcon } from "../components/ui/icons";
import { setCookie } from "../components/utils/cookies";
import { formatCodePatient, formatPass } from "../components/utils/errors";
import { InputForm } from "../components/global-components/input";
import useForm from "../hooks/useForm";
import { Status, useAuth } from "../hooks/useAuth";
import { authStatus, useAuthContext } from "../context/AuthContext";
import { COOKIE_ID, COOKIE_TOKEN } from "../components/utils/constants";

export const Login = () => {
  const { updateAuth, user, status: statusAuth } = useAuthContext();

  const { verifyAuthentication, message, status } = useAuth();

  const [showFormatInvalid, setShowFormatInvalid] = React.useState("");
  const history = useHistory();

  const stateSchema = {
    codePatient: { value: "", error: "" },
    password: { value: "", error: "" },
  };

  React.useEffect(() => {
    if (statusAuth === authStatus.Ready) {
      if (user) {
        history.replace("/auth/results");
      }
    }
  }, [statusAuth]);

  const stateValidatorSchema = {
    codePatient: {
      required: true,
      validator: formatCodePatient(),
    },
    password: {
      required: true,
      validator: formatPass(),
      nospaces: true,
    },
  };

  const checkFormat = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.indexOf(" ") >= 0) {
      setShowFormatInvalid("No se permite espacios");
    } else {
      setShowFormatInvalid("");
    }
  };

  const onSubmitForm = async (data: any) => {
    const params: any = {
      username: data?.codePatient,
      password: data?.password,
    };
    const res = await verifyAuthentication(params);
    if (res?.status === 200) {
      const aux = { ...res?.doc };
      delete aux?._id;
      delete aux?.lastDayOfSession;
      setCookie(COOKIE_ID, res?.doc?._id || "");
      setCookie(COOKIE_TOKEN, res?.doc?.lastDayOfSession?.toString() || "");
      updateAuth(aux);
      history.push("/auth/results");
    }
  };

  const {
    values: { codePatient, password },
    errors: { codePatient: codePatientError, password: passwordError },
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  return (
    <div className="signwall">
      <div className="signwall-container">
        <div className="banner">
          <img src={Banner} className="banner-image" alt="labbio srl" />
        </div>
        <form className="signwall-container-form" onSubmit={handleOnSubmit}>
          {message && status === Status.Error ? (
            <div className="alert alert-danger">
              <span>{message}</span>
            </div>
          ) : null}
          <div className="signwall-container-form__title-container">
            <p className="signwall-container-form__title">
              Consulta tus resultados
            </p>
          </div>
          <div className="input-group">
            <span className="input-group-text">
              <UserIcon fill="#000"></UserIcon>
            </span>
            <InputForm
              className="form-control"
              type="text"
              name="codePatient"
              value={codePatient}
              placeholder="Código de Paciente"
              onChange={(e) => {
                handleOnChange(e);
              }}
              error={codePatientError}
            />
          </div>
          <div className="input-group">
            <span className="input-group-text">
              <PasswordIcon fill="#000"></PasswordIcon>
            </span>
            <InputForm
              className="form-control"
              type="password"
              name="password"
              required
              value={password || ""}
              placeholder="Contraseña"
              autoComplete="current-password"
              onChange={(e) => {
                handleOnChange(e);
                checkFormat(e);
              }}
              error={passwordError || showFormatInvalid}
            />
          </div>
          <div className="button-group">
            <button
              type="submit"
              className="btn btn-success w-100"
              disabled={
                disable || status === Status.Loading || showFormatInvalid
                  ? true
                  : false
              }
            >
              Ingresar{status === Status.Loading ? "..." : ""}
            </button>
          </div>
          <button
            type="button"
            className="btn btn-dark mt-1"
            onClick={() => history.push("/")}
          >
            Regresar
          </button>
        </form>
      </div>
    </div>
  );
};
