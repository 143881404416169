import { DownloadIcon } from "../ui/icons";
import { setFormatDate } from "../utils/formats";

import { Exam } from "../../hooks/usePublic";

type ExamItemProps = {
  orderNumber: number;
  exam: Exam;
};

export const ExamItem: React.FC<ExamItemProps> = ({ orderNumber, exam }) => {
  const { createdDate, doctor, examNumber, document } = exam || {};
  return (
    <tr>
      <td>{orderNumber}</td>
      <td>{examNumber}</td>
      <td>
        {`${doctor?.firstName || ""} ${doctor?.lastName || ""} ${
          doctor?.secondLastName || ""
        }`.trim() || ""}
      </td>
      <td>{setFormatDate({ order: 0, date: createdDate })}</td>
      <td>
        <button
          className="btn btn-dark"
          onClick={() => window.open(document, "_blank")}
        >
          <DownloadIcon></DownloadIcon>
        </button>
      </td>
    </tr>
  );
};
