import { Link } from "react-router-dom";

export const Page404 = () => {
  return (
    <div className="error-404">
      <div className="error-404-container">
        <p className="error-404-container__title">Página no encontrada</p>
        <Link to={"/"} className="error-404-container__link">
          Volver a la homepage
        </Link>
      </div>
    </div>
  );
};
