import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { Status, useAuth } from "../../hooks/useAuth";
import { LogoutIcon } from "../ui/icons";

export const LogoutButton = () => {
  const history = useHistory();
  const { cleanAuth } = useAuthContext();
  const { logoutUser, status } = useAuth();
  const closeSession = async () => {
    await logoutUser()
      .then(() => {
        cleanAuth();
        history.replace("/login");
      })
      .catch(() => {
        cleanAuth();
        history.replace("/login");
      });
  };

  return (
    <div className="logoutButton">
      <button
        onClick={() => closeSession()}
        type="button"
        disabled={status === Status.Loading}
        className="btn btn-dark w-100"
      >
        {status === Status.Loading ? "Cerrando Sesión..." : "Cerrar Sesión"}
        &nbsp;&nbsp;<LogoutIcon></LogoutIcon>
      </button>
    </div>
  );
};
