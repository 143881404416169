/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
export const Nav = () => {
  const [isActive, setIsActive] = React.useState(false);
  const [selected, setSelected] = React.useState("");

  const scrollToSection = (id: string) => {
    const el: HTMLElement | null = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "start" });
      setSelected(id);
    }
    setIsActive(false);
  };

  return (
    <nav className="nav">
      <button className="nav__menu" onClick={() => setIsActive(!isActive)}>
        <i className="fa-solid fa-bars text-white fs-4"></i>
      </button>
      <ul className={isActive ? "active" : ""}>
        <li
          onClick={() => scrollToSection("root")}
          className={selected.includes("root") ? "active" : ""}
        >
          <a>INICIO</a>
        </li>
        <li
          onClick={() => scrollToSection("services")}
          className={selected.includes("services") ? "active" : ""}
        >
          <a>SERVICIOS Y ESPECIALIDADES</a>
        </li>
        <li
          onClick={() => scrollToSection("objetives")}
          className={selected.includes("objetives") ? "active" : ""}
        >
          <a>VISION Y MISION</a>
        </li>
        <li
          onClick={() => scrollToSection("who_are_we")}
          className={selected.includes("who_are_we") ? "active" : ""}
        >
          <a>¿QUIENES SOMOS?</a>
        </li>
      </ul>
    </nav>
  );
};
