// @ts-ignore
import Background from "../../assets/imgs/labbio-fondo.jpg";

export const classes = {
  cover: "cover",
  coverBackgroundContainer: "cover--background-container",
  coverBackgroundImage: "cover--background-image",
  coverContainer: "cover-container",
  coverContainerDescription: "cover-container--description",
};

export const Cover = () => {
  return (
    <div className={classes.cover} id="home">
      <div className={classes.coverBackgroundContainer}>
        <img
          src={Background}
          className={classes.coverBackgroundImage}
          alt="banner labbio"
        ></img>
      </div>
      {/* <div className={classes.coverContainer}>
        <p className={classes.coverContainerDescription}>
          Sé parte de nuestra familia, te esperamos
        </p>
      </div> */}
    </div>
  );
};
