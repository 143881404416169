/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Footer } from "../components/ui/footer";
import { LogoutButton } from "../components/global-components/logoutButton";
import { PaginateButtons } from "../components/ui/paginateButtons";
import { InputForm } from "../components/global-components/input";
import { formatExceedDate } from "../components/utils/errors";
import useForm from "../hooks/useForm";
import { setFormatDate } from "../components/utils/formats";
import { useAuthContext, authStatus } from "../context/AuthContext";
import { generateLastPath } from "../components/utils/session";
import { Exam, Status, useExams } from "../hooks/usePublic";

// @ts-ignore
import Logo from "../assets/imgs/logo-blanco-2.png";
import { getCookie } from "../components/utils/cookies";
import { COOKIE_ID, COOKIE_TOKEN } from "../components/utils/constants";
import { ExamItem } from "../components/exams/exam";

export const Exams = () => {
  const { user, status } = useAuthContext();
  const {
    changePage,
    getExamsByFilter,
    exams,
    searchFilter,
    status: useStatus,
    paginateData,
  } = useExams();
  const history = useHistory();

  React.useEffect(() => {
    const token = getCookie(COOKIE_TOKEN);
    const userID = getCookie(COOKIE_ID);
    if (!token || !userID) {
      history.replace("/login");
    }
  }, [authStatus]);

  React.useEffect(() => {
    if (status === authStatus.Ready) {
      if (!user) {
        history.replace("/login");
      } else {
        generateLastPath();
      }
    }
  }, [status]);

  const LIMIT_DOCS = 20;

  React.useEffect(() => {
    if (status === authStatus.Ready) {
      if (user) {
        getExamsByFilter(
          { startDate: "", endDate: "" },
          { limit: LIMIT_DOCS, pageSize: 1 }
        );
      }
    }
  }, [status]);

  const stateSchema = {
    startDate: { value: "", error: "" },
    endDate: { value: "", error: "" },
  };

  const validators = {
    required: true,
    validator: formatExceedDate(),
    invalidtext: true,
  };

  const stateValidatorSchema = {
    startDate: {
      ...validators,
    },
    endDate: {
      ...validators,
    },
  };

  const handleSearch = ({
    startDate,
    endDate,
  }: {
    startDate: string | null;
    endDate: string | null;
  }) => {
    let start = "";
    let end = "";
    if (startDate) {
      start = `${startDate.replace("/", "-")}T00:00:00.0+00:00`;
    }
    if (endDate) {
      end = `${endDate.replace("/", "-")}T23:59:59.999+00:00`;
    }
    getExamsByFilter(
      { startDate: start, endDate: end },
      { limit: LIMIT_DOCS, pageSize: 1 }
    );
  };

  const {
    values: { startDate, endDate },
    errors: { startDate: startDateError, endDate: endDateError },
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, handleSearch);

  return (
    <>
      <div className="exams">
        <div className="exams__header">
          <img className="exams__header__image" src={Logo} alt="logo"></img>
          <p className="exams__header__patient">
            <span className="fw-bold">Paciente:</span> {user?.firstName || ""}{" "}
            {user?.lastName || ""}
          </p>
          <LogoutButton></LogoutButton>
        </div>
        <div className="exams-container">
          <form className="exams-container__filter" onSubmit={handleOnSubmit}>
            <InputForm
              type="date"
              name="startDate"
              value={startDate || ""}
              className="form-control"
              placeholder="Fecha Inicial"
              max={setFormatDate({
                order: 1,
                date: endDate || new Date(),
                separator: "-",
              })}
              showError={false}
              onChange={handleOnChange}
              error={startDateError}
            ></InputForm>
            <InputForm
              type="date"
              value={endDate || ""}
              name="endDate"
              className="form-control"
              placeholder="Fecha Final"
              max={setFormatDate({
                order: 1,
                date: new Date(),
                separator: "-",
              })}
              showError={false}
              onChange={handleOnChange}
              error={endDateError}
            ></InputForm>
            <button
              type="submit"
              className="btn btn-info w-100"
              disabled={disable}
            >
              Filtrar
            </button>
          </form>
          <div className="exams-container__table">
            {useStatus === Status.Loading ? (
              <p className="fw-bold fs-4 text-center my-auto mx-auto">
                Espere un momento...
              </p>
            ) : null}
            {(useStatus === Status.Ready || useStatus === Status.Updating) &&
            exams.length > 0 ? (
              <table
                className="table w-100"
                style={{
                  minWidth: "800px",
                }}
              >
                <thead>
                  <tr>
                    <th>Nro.</th>
                    <th>Número de Examen</th>
                    <th>Médico</th>
                    <th>Fecha de Conclusión</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {exams.map((exam: Exam, index: number) => (
                    <ExamItem key={index} exam={exam} orderNumber={index + 1} />
                  ))}
                </tbody>
              </table>
            ) : (
              <>
                {useStatus === Status.Ready &&
                !searchFilter?.startDate &&
                !searchFilter?.endDate &&
                exams.length === 0 ? (
                  <p className="fw-bold fs-4 text-center my-auto mx-auto">
                    Aún no se encuentran exámenes disponibles
                  </p>
                ) : null}
                {useStatus === Status.Ready &&
                searchFilter?.startDate &&
                searchFilter?.endDate &&
                exams.length === 0 ? (
                  <p className="fw-bold fs-4 text-center my-auto mx-auto">
                    Exámenes no encontrados
                  </p>
                ) : null}
              </>
            )}
          </div>
          {exams.length > 0 ? (
            <div className="exams-container__paginate">
              <PaginateButtons
                paginate={paginateData}
                handleChange={changePage}
              ></PaginateButtons>
            </div>
          ) : null}
        </div>
        <Footer></Footer>
      </div>
    </>
  );
};
