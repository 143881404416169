// @ts-ignore
import Icono from "../../assets/imgs/ico-resultado.png";
import { Link } from "react-router-dom";
import { SOCIAL_MEDIA } from "../utils/constants";

const classes = {
  header: "header bg-secondary",
  headerContainer: "header__container",
  headerPhoneContainer: "header__container__phone-container",
  headerPhoneMiniContainer: "header__container__phone-container--mini",
  headerPhoneMiniContainerIcon: "header__container__phone-container--mini-icon",
  headerPhoneMiniContainerText: "header__container__phone-container--mini-text",
  headerScheduleContainer: "header__container__schedules-container",
  headerScheduleContainerText: "header__container__schedules-container-text",
  headerScheduleContainerMobile:
    "header__container__schedules-container-mobile",
  headerScheduleContainerMobileText:
    "header__container__schedules-container-mobile-text",
  headerResultsContainer: "header__container__exams-container",
  headerResultsMiniContainer: "header__container__exams-container--mini",
  headerResultsMiniContainerIcon:
    "header__container__exams-container--mini-icon",
  headerResultsMiniContainerText:
    "header__container__exams-container--mini-text m-0",
};

export const Header = () => {
  return (
    <header className={classes.header}>
      <div className={classes.headerContainer}>
        <div className={classes.headerPhoneContainer}>
          <div className={classes.headerPhoneMiniContainer}>
            <div
              className="flex p-1 items-center pointer"
              onClick={() => window?.open(SOCIAL_MEDIA.WHATSAPP, "_blank")}
            >
              <i className="fa-brands fa-whatsapp fs-3 text-third fw-bold"></i>
              <span
                className={`${classes.headerPhoneMiniContainerText} ml-2 fs-5 text-third fw-bold`}
              >
                +51 948-840-543
              </span>
            </div>
          </div>
        </div>
        <div className={`${classes.headerScheduleContainer}`}>
          <p
            className={`${classes.headerScheduleContainerText} ml-2 text-third fw-bold`}
          >
            Lunes a Viernes: 7:00am a 8:00pm
          </p>
          <p
            className={`${classes.headerScheduleContainerText} ml-2 text-third fw-bold`}
          >
            Sábados: 7:30am a 12:00pm
          </p>
        </div>
        <div className={classes.headerResultsContainer}>
          <Link
            className={`${classes.headerResultsMiniContainer} items-center flex px-1`}
            to={"/login"}
          >
            <div className="flex flex-column p-1 pointer">
              <img
                src={Icono}
                alt="resultado"
                className={classes.headerResultsMiniContainerIcon}
              />
            </div>
            <div className="flex flex-column p-1">
              <p className={classes.headerResultsMiniContainerText}>Consulta</p>
              <p className={classes.headerResultsMiniContainerText}>
                Resultados
              </p>
            </div>
          </Link>
        </div>
      </div>
      <div className={classes.headerScheduleContainerMobile}>
        <p className={`${classes.headerScheduleContainerMobileText}`}>
          Lunes a Viernes: 7:00am a 8:00pm
        </p>
        <p className={`${classes.headerScheduleContainerMobileText}`}>
          Sábados: 7:30am a 12:00pm
        </p>
      </div>
    </header>
  );
};
