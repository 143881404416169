import { SOCIAL_MEDIA } from "../utils/constants";

export const Footer = () => {
  const redirectToSocial = (e: any) => {
    const social = e?.target.dataset.id || "";
    switch (social) {
      case "facebook":
        window.open(SOCIAL_MEDIA.FACEBOOK, "_blank");
        break;
      case "whatsapp":
        window.open(SOCIAL_MEDIA.WHATSAPP, "_blank");
        break;
      // case "twitter":
      //   window.open("https://www.twitter.com/", "_blank");
      //   break;
      // case "youtube":
      //   window.open("https://www.youtube.com/", "_blank");
      //   break;
      // case "linkedin":
      //   window.open("https://www.linkedin.com/", "_blank");
      //   break;
    }
  };
  return (
    <div className="footer">
      <div className="footer-reserved">
        © {new Date().getFullYear()} Labbio SRL - Todos los derechos reservados
      </div>
      <div className="footer-social">
        <div
          className="facebook link pointer"
          data-id="facebook"
          onClick={(e) => redirectToSocial(e)}
        >
          <i className="fa-brands fa-facebook"></i>&nbsp;&nbsp;facebook
        </div>
        <div
          className="whatsapp link pointer"
          data-id="whatsapp"
          onClick={(e) => redirectToSocial(e)}
        >
          <i className="fa-brands fa-whatsapp"></i>&nbsp;&nbsp;whatsapp
        </div>
      </div>
    </div>
  );
};
