/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useHistory,
} from "react-router-dom";
import { Home } from "../pages/home";
import { Page404 } from "../pages/404";
import { Login } from "../pages/login";

import {
  AuthProvider,
  useAuthContext,
  authStatus,
} from "../context/AuthContext";
import { Exams } from "../pages/exams";
import firebase, { FirebaseContext } from "../firebase";

const AppRouter = () => {
  const { user, status, cleanAuth } = useAuthContext();
  const history = useHistory();

  React.useEffect(() => {
    if (status === authStatus.Ready) {
      if (!user) {
        if (window.location.href.includes("/auth/")) {
          history.replace("/login");
        }
        cleanAuth();
      }
    }
  }, [status]);

  return (
    <FirebaseContext.Provider value={{ firebase }}>
      <Router>
        <Switch>
          <Route path="/login" exact={true} component={Login} />
          <Route path="/auth/results" exact={true} component={Exams} />
          <Route path="/" exact={true} component={Home} />
          <Route path="/404" exact={true} component={Page404} />
          <Route path="*" component={Page404} />
          <Redirect to="/404" />
        </Switch>
      </Router>
    </FirebaseContext.Provider>
  );
};

export const AppRouterComponentContainer = () => (
  <Router>
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  </Router>
);
